@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Advent+Pro:wght@700&family=Inter:wght@400;500;600;700&family=Montserrat:wght@400;500;600&family=Noto+Sans:wght@700&display=swap");

body {
  @apply bg-gray-800;
}

body.lock {
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 18px;
}

::-webkit-scrollbar-track {
  background-color: #37384f;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #28293d;
}

summary {
  display: block;
}

details > summary {
  list-style: none;
}

details > summary::marker, /* Latest Chrome, Edge, Firefox */ 
  details > summary::-webkit-details-marker /* Safari */ {
  display: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.mySwiper {
  margin: 0px -24px;
  padding: 0px 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}
